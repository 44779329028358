import {Contract} from 'web3-eth-contract';
import Web3 from 'web3';
import {TransactionReceipt} from 'web3-eth';
import {ConnectedWallet} from '@privy-io/react-auth';
import {Alchemy, Network, Utils} from 'alchemy-sdk';
import request from 'axios';

import {ETH_NETWORK, ETH_PROVIDER} from '@/utils/contracts/network';

import {
  FACTION_NFT_CONTRACT_ADDRESS,
  FACTION_NFT_CONTRACT_ABI,
} from '@/utils/white-rabbit/contracts/faction-contracts';

import {
  WR_TOKEN_CONTRACT_ABI,
  WR_TOKEN_CONTRACT_ADDRESS,
  WRAB_DISTRIBUTOR_CONTRACT_ADDRESS,
  WRAB_DISTRIBUTOR_CONTRACT_ABI,
  WRAB_DISTRIBUTORV2_CONTRACT_ABI,
  WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS,
} from '@/utils/white-rabbit/contracts/wrab-contracts';

import {
  WR_KEY_CONTRACT_ABI,
  WR_KEY_CONTRACT_ADDRESS,
} from '@/utils/white-rabbit/contracts/wr-key-contracts';

import {
  REPLICANTX_STAKING_CONTRACT_ABI,
  REPLICANTX_STAKING_CONTRACT_ADDRESS,
} from '@/utils/white-rabbit/contracts/replicant-x';

import {
  PRODUCER_PASS_CONTRACT_ADDRESS,
  DISTRIBUTION_CONTRACT_ADDRESS,
  DISTRIBUTION_CONTRACT_ABI,
  PRODUCER_PASS_CONTRACT_ABI,
  PRODUCER_PASS_STAKING_CONTRACT_ABI,
  PRODUCER_PASS_STAKING_CONTRACT_ADDRESS,
} from '@/utils/white-rabbit/contracts/producer-pass-contracts';

import {
  COOKSHOP_CONTRACT_ABI,
  COOKSHOP_CONTRACT_ADDRESS,
  WR_STEAK_CONTRACT_ABI,
  WR_STEAK_CONTRACT_ADDRESS,
} from '@/utils/white-rabbit/contracts/steak-contracts';

import {
  WR_CONTRACT_ABI,
  WR_CONTRACT_ADDRESS,
} from '@/utils/contracts/contracts';

import {
  logEtherscanTransactionUrl,
  formatWrTokenAmount,
  calculateEthHexFromWei,
  times,
} from '@/utils/shared';
import {
  FactionMetaData,
  getFactionMetadata,
} from '@/utils/white-rabbit/factions';
import {ProducerPass} from '@/utils/types';

const TXN_TIMEOUT = 3 * 60 * 1000; // 3 mins

const CURRENT_EPISODE_ID = 5;

const noop = () => {};

const getAlchemyApiKeyByUrl = (url?: string) => {
  if (!url) {
    return null;
  }

  const arr = url.split('/');

  return arr[arr.length - 1];
};

const ALCHEMY_API_KEY =
  process.env.NEXT_PUBLIC_ALCHEMY_API_KEY ||
  getAlchemyApiKeyByUrl(ETH_PROVIDER);

export const STAKING_APPROVAL_TIMEOUT_ERROR_MESSAGE =
  'Transactions may take a few minutes to complete. Check back in a bit.';

type TransactionRecord = {
  from: string;
  to: string;
  data: any;
  value?: string;
  gas?: number;
};

export type AlchemyNft = {
  attributes?: Array<Record<string, any>>;
  id: number;
  image?: string;
  name?: string;
  address?: string;
};

type TransactionCallbacks = {
  onTransactionHash?: (txn: string) => void;
  onConfirmation?: (confirmation: any, receipt: TransactionReceipt) => void;
  onReceipt?: (receipt: TransactionReceipt) => void;
  onError?: (error: any) => void;
  onTimeout?: (txn: string) => void;
};

class WRWeb3Client {
  web3: Web3;
  alchemy: Alchemy;
  producerPassContract: Contract;
  distributionContract: Contract;
  replicantXStakingContract: Contract;
  //   shibuyaPlatformContract: Contract;
  wrTokenContract: Contract;
  producerPassStakingContract: Contract;
  factionNFTContract: Contract;
  wrabDistributorContract: Contract;
  wrabDistributorV2Contract: Contract;
  wrKeyContract: Contract;
  cookshopContract: Contract;
  steakContract: Contract;
  whiteRabbitContract: Contract;

  constructor() {
    this.web3 = new Web3(ETH_PROVIDER);
    this.alchemy = new Alchemy({
      network: `eth-${ETH_NETWORK}` as Network,
      apiKey: ALCHEMY_API_KEY!,
    });
    console.log('Contract addresses:');
    console.table({
      WR_TOKEN_CONTRACT_ADDRESS,
      PRODUCER_PASS_CONTRACT_ADDRESS,
      DISTRIBUTION_CONTRACT_ADDRESS,
      PRODUCER_PASS_STAKING_CONTRACT_ADDRESS,
      FACTION_NFT_CONTRACT_ADDRESS,
      WRAB_DISTRIBUTOR_CONTRACT_ADDRESS,
    });

    this.producerPassContract = new this.web3.eth.Contract(
      PRODUCER_PASS_CONTRACT_ABI,
      PRODUCER_PASS_CONTRACT_ADDRESS
    );
    this.wrTokenContract = new this.web3.eth.Contract(
      WR_TOKEN_CONTRACT_ABI,
      WR_TOKEN_CONTRACT_ADDRESS
    );
    this.distributionContract = new this.web3.eth.Contract(
      DISTRIBUTION_CONTRACT_ABI,
      DISTRIBUTION_CONTRACT_ADDRESS
    );
    this.producerPassStakingContract = new this.web3.eth.Contract(
      PRODUCER_PASS_STAKING_CONTRACT_ABI,
      PRODUCER_PASS_STAKING_CONTRACT_ADDRESS
    );
    this.factionNFTContract = new this.web3.eth.Contract(
      FACTION_NFT_CONTRACT_ABI,
      FACTION_NFT_CONTRACT_ADDRESS
    );
    this.wrabDistributorContract = new this.web3.eth.Contract(
      WRAB_DISTRIBUTOR_CONTRACT_ABI,
      WRAB_DISTRIBUTOR_CONTRACT_ADDRESS
    );
    this.wrabDistributorV2Contract = new this.web3.eth.Contract(
      WRAB_DISTRIBUTORV2_CONTRACT_ABI,
      WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS
    );
    this.replicantXStakingContract = new this.web3.eth.Contract(
      REPLICANTX_STAKING_CONTRACT_ABI,
      REPLICANTX_STAKING_CONTRACT_ADDRESS
    );
    this.wrKeyContract = new this.web3.eth.Contract(
      WR_KEY_CONTRACT_ABI,
      WR_KEY_CONTRACT_ADDRESS
    );
    this.cookshopContract = new this.web3.eth.Contract(
      COOKSHOP_CONTRACT_ABI,
      COOKSHOP_CONTRACT_ADDRESS
    );
    this.steakContract = new this.web3.eth.Contract(
      WR_STEAK_CONTRACT_ABI,
      WR_STEAK_CONTRACT_ADDRESS
    );
    this.whiteRabbitContract = new this.web3.eth.Contract(
      WR_CONTRACT_ABI,
      WR_CONTRACT_ADDRESS
    );
  }

  async handleSendTransaction(
    transaction: TransactionRecord,
    callbacks: TransactionCallbacks = {}
  ): Promise<TransactionReceipt> {
    const {
      onTransactionHash = noop,
      onConfirmation = noop,
      onReceipt = noop,
      onError = noop,
      onTimeout = noop,
    } = callbacks;

    return new Promise((resolve, reject) => {
      let timeout: NodeJS.Timeout;

      this.web3.eth
        .sendTransaction(transaction)
        .on('transactionHash', (txn: string) => {
          logEtherscanTransactionUrl(txn, {
            network: ETH_NETWORK,
            debug: true,
          });

          timeout = setTimeout(() => onTimeout(txn), TXN_TIMEOUT);

          onTransactionHash(txn);
        })
        .on('confirmation', (confirmation: any, receipt: any) => {
          onConfirmation(confirmation, receipt);
        })
        .on('receipt', (receipt: TransactionReceipt) => {
          onReceipt(receipt);
          clearTimeout(timeout);

          return resolve(receipt);
        })
        .on('error', (error: any) => {
          onError(error);
          clearTimeout(timeout);

          return reject(error);
        });
    });
  }

  async handleSendTransactionV2(
    connectedWallet: ConnectedWallet,
    transaction: TransactionRecord,
    callbacks: TransactionCallbacks = {}
  ) {
    const provider = await connectedWallet.getEthersProvider();
    const signer = await provider.getSigner();

    const {
      onTransactionHash = noop,
      onConfirmation = noop,
      onReceipt = noop,
      onError = noop,
      onTimeout = noop,
    } = callbacks;
    /*

      Consider adding a buffer for the transaction gas here

      const gas = await this.web3.eth.estimateGas(transaction);
      const bufferedGas = Math.floor(gas * 1.2);

      signer.sendTransaction({
        ...transaction
        gas: bufferedGas,
      })
    */
    const transactionResponse = await signer.sendTransaction(transaction);

    return this.alchemy.transact
      .waitForTransaction(transactionResponse.hash)
      .catch((error) => {
        console.error('Error waiting for transaction', error);
        onError(error);
        return Promise.reject(error);
      });
  }

  async mintPassesForEpisode(
    {
      connectedWallet,
      episodeId,
      numberToMint,
      priceInWei,
      whitelist,
    }: {
      connectedWallet: ConnectedWallet;
      episodeId: number;
      numberToMint: number;
      priceInWei: string;
      whitelist?: boolean;
    },
    callbacks: TransactionCallbacks = {}
  ) {
    const ethAmountHex = calculateEthHexFromWei({
      wei: priceInWei,
      multiplier: numberToMint,
    });
    let transaction: TransactionRecord;
    if (whitelist) {
      transaction = {
        from: connectedWallet.address,
        to: DISTRIBUTION_CONTRACT_ADDRESS,
        value: ethAmountHex,
        data: this.distributionContract.methods
          .mintProducerPass(episodeId, numberToMint)
          .encodeABI(),
      };
    } else {
      transaction = {
        from: connectedWallet.address,
        to: PRODUCER_PASS_CONTRACT_ADDRESS,
        value: ethAmountHex,
        data: this.producerPassContract.methods
          .mintProducerPass(episodeId, numberToMint)
          .encodeABI(),
      };
    }

    return this.handleSendTransactionV2(
      connectedWallet,
      transaction,
      callbacks
    );
  }

  async earlyMintPassesForEpisode({
    connectedWallet,
    episodeId,
    allocatedMint,
    numberToMint,
    priceInWei,
    merkleProof,
  }: {
    connectedWallet: ConnectedWallet;
    episodeId: number;
    allocatedMint: number;
    numberToMint: number;
    priceInWei: string;
    merkleProof: string[];
  }) {
    const ethAmountHex = calculateEthHexFromWei({
      wei: priceInWei,
      multiplier: numberToMint,
    });
    const transaction: TransactionRecord = {
      from: connectedWallet.address,
      to: DISTRIBUTION_CONTRACT_ADDRESS,
      value: ethAmountHex,
      data: this.distributionContract.methods
        .earlyMintProducerPass(
          episodeId,
          allocatedMint,
          numberToMint,
          merkleProof
        )
        .encodeABI(),
    };

    return this.handleSendTransactionV2(connectedWallet, transaction);
  }

  async getProducerPassBalanceForEpisode({
    connectedAddress,
    episodeId,
  }: {
    connectedAddress: string;
    episodeId: number;
  }): Promise<number> {
    const balance = await this.producerPassContract.methods
      .balanceOf(connectedAddress, episodeId)
      .call();
    return Number(balance) || 0;
  }

  async getProducerPassBalanceForAllEpisodes({
    connectedAddress,
  }: {
    connectedAddress: string;
  }): Promise<number> {
    const currentEpisodeId = CURRENT_EPISODE_ID;
    let promises = [];
    for (let episodeId = 1; episodeId <= currentEpisodeId; episodeId++) {
      promises.push(
        this.getProducerPassBalanceForEpisode({connectedAddress, episodeId})
      );
    }
    const balances = await Promise.all(promises);
    const total = balances.reduce((sum, balance) => sum + balance, 0);
    return total;
  }

  /* For Ch 5 specificially we have WL and public mint happening
   * at the same time over two contracts */
  async getProducerPassesUserMintedForEpisodeCh5({
    connectedAddress,
    episodeId,
  }: {
    connectedAddress: string;
    episodeId: number;
  }): Promise<number> {
    const wlCount = await this.distributionContract.methods
      .userPassesMintedByEpisodeId(episodeId)
      .call({from: connectedAddress});
    const publicMintCount = await this.producerPassContract.methods
      .balanceOf(connectedAddress, episodeId)
      .call();
    return Number(wlCount) + Number(publicMintCount) || 0;
  }

  async getProducerPassesMintedForEpisode({
    connectedAddress,
    episodeId,
  }: {
    connectedAddress: string;
    episodeId: number;
  }): Promise<number> {
    const count = await this.distributionContract.methods
      .userPassesMintedByEpisodeId(episodeId)
      .call({from: connectedAddress});

    return Number(count) || 0;
  }

  async getProducerPassesEarlyMintedForEpisode({
    connectedAddress,
    episodeId,
  }: {
    connectedAddress: string;
    episodeId: number;
  }): Promise<number> {
    const count = await this.distributionContract.methods
      .userPassesEarlyMintedByEpisodeId(episodeId)
      .call({from: connectedAddress});

    return Number(count) || 0;
  }

  async getWRTokenTotalSupply(): Promise<number> {
    const total = await this.wrTokenContract.methods.totalSupply().call();

    return formatWrTokenAmount(Number(total) || 0);
  }

  async getWRTokenBalance(connectedAddress: string): Promise<number> {
    const balance = await this.wrTokenContract.methods
      .balanceOf(connectedAddress)
      .call();

    return formatWrTokenAmount(Number(balance) || 0);
  }

  async getProducerPassTotalSupply(episodeId: number): Promise<number> {
    const total = await this.producerPassContract.methods
      .totalSupply(episodeId)
      .call();

    return Number(total) || 0;
  }

  //   async unstakeProducerPasses({
  //     connectedWallet,
  //     episodeId,
  //     episodeOptionId,
  //   }: {
  //     connectedWallet: ConnectedWallet;
  //     episodeId: number;
  //     episodeOptionId: number;
  //   }) {
  //     const transaction = {
  //       from: connectedWallet.address,
  //       to: SHIBUYA_SMART_CONTRACT_ADDRESS,
  //       data: this.shibuyaPlatformContract.methods
  //         .unstakeProducerPasses(episodeId, episodeOptionId)
  //         .encodeABI(),
  //     };
  //     return this.handleSendTransactionV2(connectedWallet, transaction);
  //   }

  async getUserEpisodeVotes({
    connectedAddress,
    episodeId,
    episodeOptionId,
  }: {
    connectedAddress: string;
    episodeId: number;
    episodeOptionId: number;
  }): Promise<number> {
    const count = await this.whiteRabbitContract.methods
      .userStakedProducerPassCount(episodeId, episodeOptionId)
      .call({
        from: connectedAddress,
      });

    return Number(count) || 0;
  }

  //   async getUserEpisodeVoteHistory({
  //     connectedAddress,
  //     episodeId,
  //     episodeOptionId,
  //   }: {
  //     connectedAddress: string;
  //     episodeId: number;
  //     episodeOptionId: number;
  //   }): Promise<number> {
  //     const count = await this.shibuyaPlatformContract.methods
  //       .userStakedProducerPassCountHistory(episodeId, episodeOptionId)
  //       .call({
  //         from: connectedAddress,
  //       });

  //     return Number(count) || 0;
  //   }

  //   async getCurrentEpisodeId(): Promise<number | null> {
  //     const count = await this.shibuyaPlatformContract.methods
  //       .getCurrentEpisodeCount()
  //       .call();

  //     const num = Number(count);

  //     if (!num || num === 0) {
  //       return null;
  //     }

  //     const index = num - 1;
  //     const latest = await this.shibuyaPlatformContract.methods
  //       .episodes(index)
  //       .call();

  //     return Number(latest) || null;
  //   }

  //   async getEpisodeVotes({
  //     episodeId,
  //     episodeOptionId,
  //   }: {
  //     episodeId: number;
  //     episodeOptionId: number;
  //   }): Promise<number> {
  //     const count = await this.shibuyaPlatformContract.methods
  //       .episodeVotes(episodeId, episodeOptionId)
  //       .call();

  //     return Number(count) || 0;
  //   }

  // For Ch 5 distribution, some producer passes (for WL) live in distribution contract
  // and others in the original producer pass contract
  // since WL mint is done at same time as public mint.
  async getProducerPassAvailable(
    episodeId: number,
    whitelist?: boolean
  ): Promise<number> {
    let available;

    if (episodeId == 5) {
      if (whitelist) {
        available = await this.producerPassContract.methods
          .balanceOf(DISTRIBUTION_CONTRACT_ADDRESS, episodeId)
          .call();
      } else {
        const pass = await this.getProducerPassByEpisodeId(episodeId);
        const numMintedOrReserved = await this.getProducerPassTotalSupply(
          episodeId
        );
        const maxSupply = pass.maxSupply;
        available = maxSupply - numMintedOrReserved;
      }
    } else {
      available = await this.getProducerPassTotalSupply(episodeId);
    }

    return Number(available) || 0;
  }

  // price and maxPerWallet differ for whitelist minting
  async getProducerPassByEpisodeId(
    episodeId: number,
    whitelist?: boolean
  ): Promise<ProducerPass> {
    let pass;

    if (whitelist) {
      pass = await this.distributionContract.methods
        .getEpisodeToProducerPass(episodeId)
        .call();
    } else {
      pass = await this.producerPassContract.methods
        .getEpisodeToProducerPass(episodeId)
        .call();
    }
    return {
      price: pass.price,
      episodeId: pass.episodeId,
      maxPerWallet: Number(pass.maxPerWallet) || 0,
      maxSupply: Number(pass.maxSupply) || 0,
      openMintTimestamp: Number(pass.openMintTimestamp) || null,
      merkleRoot: pass.merkleRoot,
    };
  }

  //   async isVotingEnabledForEpisode(episodeId: number): Promise<boolean> {
  //     const isEnabled = await this.shibuyaPlatformContract.methods
  //       .votingEnabledForEpisode(episodeId)
  //       .call();

  //     return !!isEnabled;
  //   }

  //   async getWinningOptionByEpisode(episodeId: number): Promise<number | null> {
  //     const winnerId = await this.shibuyaPlatformContract.methods
  //       .winningVoteOptionByEpisode(episodeId)
  //       .call();

  //     return winnerId ? Number(winnerId) : null;
  //   }

  //   async getUserWinningBonus(
  //     episodeId: number,
  //     episodeOptionId: number
  //   ): Promise<number> {
  //     const bonus = await this.shibuyaPlatformContract.methods
  //       .getUserWinningBonus(episodeId, episodeOptionId)
  //       .call();

  //     return formatWrTokenAmount(Number(bonus) || 0);
  //   }

  async personalSign(msg: string, address: string) {
    const signature = await this.web3.eth.personal.sign(msg, address, '');

    return signature;
  }

  //   async isApprovedForStakingForContractAddress(
  //     connectedAddress: string,
  //     contractAddress: string
  //   ): Promise<boolean> {
  //     if (isReplicantXContract(contractAddress)) {
  //       return this.isApprovedForStakingReplicantX(connectedAddress);
  //     } else if (isWhiteRabbitKeyContract(contractAddress)) {
  //       return this.isApprovedForStakingKeys(connectedAddress);
  //     } else {
  //       return false;
  //     }
  //   }

  //   async isApprovedForStaking(connectedAddress: string): Promise<boolean> {
  //     const isApproved = await this.steakContract.methods
  //       .isApprovedForAll(connectedAddress, COOKSHOP_CONTRACT_ADDRESS)
  //       .call();

  //     return isApproved;
  //   }

  //   async setApprovalForStaking(
  //     connectedAddress: string,
  //     callbacks: TransactionCallbacks = {}
  //   ): Promise<TransactionReceipt> {
  //     const transaction = {
  //       from: connectedAddress,
  //       to: WR_STEAK_CONTRACT_ADDRESS,
  //       data: this.steakContract.methods
  //         .setApprovalForAll(COOKSHOP_CONTRACT_ADDRESS, true)
  //         .encodeABI(),
  //     };

  //     return this.handleSendTransaction(transaction, callbacks);
  //   }

  //   async isApprovedForStakingKeys(connectedAddress: string): Promise<boolean> {
  //     const isApproved = await this.wrKeyContract.methods
  //       .isApprovedForAll(connectedAddress, REPLICANTX_STAKING_CONTRACT_ADDRESS)
  //       .call();

  //     return isApproved;
  //   }

  //   async setApprovalForStakingKeys(
  //     connectedAddress: string,
  //     callbacks: TransactionCallbacks = {}
  //   ): Promise<TransactionReceipt> {
  //     const transaction = {
  //       from: connectedAddress,
  //       to: WR_KEY_CONTRACT_ADDRESS,
  //       data: this.wrKeyContract.methods
  //         .setApprovalForAll(REPLICANTX_STAKING_CONTRACT_ADDRESS, true)
  //         .encodeABI(),
  //     };

  //     return this.handleSendTransaction(transaction, callbacks);
  //   }

  //   async stakeSteakNfts(
  //     connectedAddress: string,
  //     tokenIds: Array<string | number>
  //   ): Promise<any> {
  //     const transaction = {
  //       from: connectedAddress,
  //       to: COOKSHOP_CONTRACT_ADDRESS,
  //       data: this.cookshopContract.methods.stake(tokenIds).encodeABI(),
  //     };

  //     try {
  //       const gas = await this.web3.eth.estimateGas(transaction);
  //       const bufferedGas = Math.floor(gas * 1.2);

  //       return this.handleSendTransaction({
  //         ...transaction,
  //         gas: bufferedGas,
  //       });
  //     } catch (e) {
  //       return this.handleSendTransaction(transaction);
  //     }
  //   }

  //   async unstakeSteakNfts(
  //     connectedAddress: string,
  //     {
  //       tokenIds,
  //       rarityMultipliers,
  //       merkleProofs,
  //     }: {
  //       tokenIds: Array<string | number>;
  //       rarityMultipliers: Array<number>;
  //       merkleProofs: Array<any>;
  //     }
  //   ): Promise<any> {
  //     const transaction = {
  //       from: connectedAddress,
  //       to: COOKSHOP_CONTRACT_ADDRESS,
  //       data: this.cookshopContract.methods
  //         .unstake(tokenIds, rarityMultipliers, merkleProofs)
  //         .encodeABI(),
  //     };

  //     try {
  //       const gas = await this.web3.eth.estimateGas(transaction);
  //       const bufferedGas = Math.floor(gas * 1.2);

  //       return this.handleSendTransaction({
  //         ...transaction,
  //         gas: bufferedGas,
  //       });
  //     } catch (e) {
  //       return this.handleSendTransaction(transaction);
  //     }
  //   }

  async getNFTs({
    contractAddresses = [],
    owner,
  }: {
    contractAddresses?: string[];
    owner: string;
  }) {
    return this.alchemy.nft.getNftsForOwner(owner, {
      contractAddresses,
    });
  }

  // Brute force getting all token metadata by iterating through every token
  // in the collection and filtering out those owned by `owner` address.
  // (Useful for testing on localhost where alchemy is not available)
  async getAllTokenMetadataByOwner(contract: Contract, owner: string) {
    const supply = await contract.methods.totalSupply().call();
    const owners = await Promise.all(
      times(supply, async (tokenId) => {
        const owner = await contract.methods.ownerOf(tokenId).call();

        return {tokenId, owner};
      })
    );

    const records = owners.filter(
      (r) => r.owner.toLowerCase() === owner.toLowerCase()
    );

    const metadata = await Promise.all(
      records.map(async ({tokenId}) => {
        const uri = await contract.methods.tokenURI(tokenId).call();
        const response = await request.get(uri);

        return {id: Number(tokenId), tokenId: tokenId, ...response.data};
      })
    );

    return metadata;
  }

  async getSteaksByOwner(owner: string): Promise<AlchemyNft[]> {
    if (ETH_NETWORK === 'localhost') {
      return this.getAllTokenMetadataByOwner(this.steakContract, owner);
    }

    const {ownedNfts = []} = await this.alchemy.nft.getNftsForOwner(owner, {
      contractAddresses: [WR_STEAK_CONTRACT_ADDRESS],
    });

    return ownedNfts.map((nft) => {
      const {tokenId} = nft;

      return {
        id: this.web3.utils.isHexStrict(tokenId)
          ? (this.web3.utils.hexToNumber(tokenId) as number)
          : Number(tokenId),
        ...nft.rawMetadata,
      };
    });
  }

  async getSteakMetadataByTokenId(tokenId: string) {
    if (ETH_NETWORK === 'localhost') {
      const uri = await this.steakContract.methods.tokenURI(tokenId).call();
      const response = await request.get(uri);

      return {id: Number(tokenId), tokenId: tokenId, ...response.data};
    }

    const {tokenId: id, rawMetadata: metadata = {}} =
      await this.alchemy.nft.getNftMetadata(WR_STEAK_CONTRACT_ADDRESS, tokenId);

    return {
      id: this.web3.utils.isHexStrict(id)
        ? this.web3.utils.hexToNumber(id)
        : Number(id),
      ...metadata,
    };
  }

  //   async getStakingEndTimestamp(): Promise<number> {
  //     const expiresAt = await this.cookshopContract.methods
  //       .stakingEndTimestamp()
  //       .call();

  //     return Number(expiresAt);
  //   }

  //   async getStakedTimestamp(tokenId: number): Promise<number> {
  //     const stakedAt = await this.cookshopContract.methods
  //       .tokenIdToLastStakedTimestamp(tokenId)
  //       .call();

  //     return Number(stakedAt);
  //   }

  //   async getMaxStakingDuration(tokenId: number): Promise<number> {
  //     const stakedAt = await this.getStakedTimestamp(tokenId);
  //     const expiresAt = await this.getStakingEndTimestamp();

  //     return Number(expiresAt) - Number(stakedAt);
  //   }

  //   async calculateStakingRewards(multiplier: number, duration: number) {
  //     const reward = await this.cookshopContract.methods
  //       .steakingRewardsForRarity(multiplier, duration)
  //       .call();

  //     return formatWrTokenAmount(Number(reward) || 0);
  //   }

  async getStakedSteaksByOwner(owner: string): Promise<any> {
    const staked = await this.cookshopContract.methods
      .numStakedSteaks(owner)
      .call();
    const num = Number(staked);

    // TODO: is there a better way to handle this?
    const promises = times(num, async (index: number) => {
      const tokenId = await this.cookshopContract.methods
        .tokenOfStakerByIndex(owner, index)
        .call();

      return this.getSteakMetadataByTokenId(tokenId);
    });

    return Promise.all(promises);
  }

  //   async getClaimableWrabForSteak(tokenId: number, rarityMultiplier: number) {
  //     try {
  //       const reward = Number(
  //         await this.cookshopContract.methods
  //           .steakingRewardsForTokenId(tokenId, rarityMultiplier)
  //           .call()
  //       );

  //       return formatWrTokenAmount(reward);
  //     } catch (err: any) {
  //       // NB: the contract errors out if this method is called on an unstaked token,
  //       // but it's probably fine to just show `0` for unstaked tokens in the frontend.
  //       console.warn('Failed to get claimable WRAB for token:', err);

  //       return 0;
  //     }
  //   }

  //   async getSteakWrabRewardPerSecond(tokenId: number, rarityMultiplier: number) {
  //     const bonding = Number(
  //       await this.cookshopContract.methods
  //         .steakingRewardsBondingCurve(rarityMultiplier)
  //         .call()
  //     );

  //     const ts = Number(
  //       await this.cookshopContract.methods
  //         .tokenIdToLastStakedTimestamp(tokenId)
  //         .call()
  //     );

  //     const now = Date.now() / 1000;
  //     const duration = now - ts;

  //     return formatWrTokenAmount(bonding * duration);
  //   }

  // White Rabbit Keys NFT staking

  async getWhiteRabbitKeysNFTsForOwner(owner: string): Promise<AlchemyNft[]> {
    const {ownedNfts = []} = await this.alchemy.nft.getNftsForOwner(owner, {
      contractAddresses: [WR_KEY_CONTRACT_ADDRESS],
    });

    return ownedNfts.map((nft) => {
      const {tokenId, contract: address} = nft;
      return {
        id: this.web3.utils.isHexStrict(tokenId)
          ? (this.web3.utils.hexToNumber(tokenId) as number)
          : Number(tokenId),
        ...nft.rawMetadata,
        ...nft.contract,
      };
    });
  }

  //   async getIsStakingEnabledForKeys(): Promise<boolean> {
  //     return await this.replicantXStakingContract.methods
  //       .isStakingEnabledForTokenContractAddress(WR_KEY_CONTRACT_ADDRESS)
  //       .call();
  //   }

  //   async isUnstakingEnabledForKeys(): Promise<boolean> {
  //     return await this.replicantXStakingContract.methods
  //       .isUnstakingEnabledForTokenContractAddress(WR_KEY_CONTRACT_ADDRESS)
  //       .call();
  //   }

  async getNumberOfKeysStakedByOwner(owner: string): Promise<number> {
    const staked = await this.replicantXStakingContract.methods
      .numStakedTokens(owner, WR_KEY_CONTRACT_ADDRESS)
      .call();
    return Number(staked);
  }

  async getWhiteRabbitKeysNFTsStakedByOwner(
    owner: string
  ): Promise<AlchemyNft[]> {
    const num = await this.getNumberOfKeysStakedByOwner(owner);
    const promises = times(num, async (index: number) => {
      const tokenId = await this.replicantXStakingContract.methods
        .tokenOfStakerByIndex(owner, index, WR_KEY_CONTRACT_ADDRESS)
        .call();
      return this.getKeyMetadataByTokenId(tokenId);
    });

    return Promise.all(promises);
  }

  async getKeyMetadataByTokenId(tokenId: string) {
    if (ETH_NETWORK === 'localhost') {
      const uri = await this.wrKeyContract.methods.tokenURI(tokenId).call();
      const response = await request.get(uri);

      return {
        id: Number(tokenId),
        tokenId: tokenId,
        address: WR_KEY_CONTRACT_ADDRESS,
        ...response.data,
      };
    }

    const {
      tokenId: id,
      rawMetadata: metadata = {},
      contract: address,
    } = await this.alchemy.nft.getNftMetadata(WR_KEY_CONTRACT_ADDRESS, tokenId);

    return {
      id: this.web3.utils.isHexStrict(id)
        ? this.web3.utils.hexToNumber(id)
        : Number(id),
      ...metadata,
      ...address,
    };
  }

  async getNumStakedProducerPassesByChapter(
    connectedAddress: string,
    chapter: number
  ): Promise<number> {
    const staked = await this.producerPassStakingContract.methods
      .stakedProducerPassesFromUser(connectedAddress, chapter)
      .call();

    return Number(staked) || 0;
  }

  async isStakingEnabledForChapter(chapter: number): Promise<boolean> {
    const isEnabled = await this.producerPassStakingContract.methods
      .isStakingEnabledForChapter(chapter)
      .call();

    return isEnabled;
  }

  async isUnstakingEnabledForChapter(chapter: number): Promise<boolean> {
    const isEnabled = await this.producerPassStakingContract.methods
      .isUnstakingEnabledForChapter(chapter)
      .call();

    return isEnabled;
  }

  async isApprovedForStakingProducerPasses(
    connectedAddress: string
  ): Promise<boolean> {
    const isApproved = await this.producerPassContract.methods
      .isApprovedForAll(
        connectedAddress,
        PRODUCER_PASS_STAKING_CONTRACT_ADDRESS
      )
      .call();

    return isApproved;
  }

  async setApprovalForStakingProducerPasses(
    connectedAddress: string,
    callbacks: TransactionCallbacks = {}
  ): Promise<TransactionReceipt> {
    const transaction = {
      from: connectedAddress,
      to: PRODUCER_PASS_CONTRACT_ADDRESS,
      data: this.producerPassContract.methods
        .setApprovalForAll(PRODUCER_PASS_STAKING_CONTRACT_ADDRESS, true)
        .encodeABI(),
    };

    return this.handleSendTransaction(transaction, callbacks);
  }

  async stakeProducerPassesV2(
    {
      connectedAddress,
      episodeIds,
      amounts,
    }: {
      connectedAddress: string;
      episodeIds: number[];
      amounts: number[];
    },
    callbacks: TransactionCallbacks = {}
  ): Promise<TransactionReceipt> {
    const transaction = {
      from: connectedAddress,
      to: PRODUCER_PASS_STAKING_CONTRACT_ADDRESS,
      data: this.producerPassStakingContract.methods
        .stakeProducerPasses(episodeIds, amounts)
        .encodeABI(),
    };
    return this.handleSendTransaction(transaction, callbacks);
  }

  async unstakeProducerPassesV2({
    connectedWallet,
    episodeIds,
    amounts,
  }: {
    connectedWallet: ConnectedWallet;
    episodeIds: number[];
    amounts: number[];
  }) {
    const transaction = {
      from: connectedWallet.address,
      to: PRODUCER_PASS_STAKING_CONTRACT_ADDRESS,
      data: this.producerPassStakingContract.methods
        .unstakeProducerPasses(episodeIds, amounts)
        .encodeABI(),
    };

    return this.handleSendTransactionV2(connectedWallet, transaction);
  }

  //   async hasClaimedWhiteRabbitKeys(connectedAddress: string): Promise<boolean> {
  //     const hasClaimed = await this.wrKeyContract.methods
  //       .hasAlreadyClaimed(connectedAddress)
  //       .call();

  //     return hasClaimed;
  //   }

  //   async claimWhiteRabbitKeys(
  //     {
  //       connectedAddress,
  //       quantity,
  //       proof,
  //     }: {
  //       connectedAddress: string;
  //       quantity: number;
  //       proof: string[];
  //     },
  //     callbacks: TransactionCallbacks = {}
  //   ): Promise<TransactionReceipt> {
  //     const transaction = {
  //       from: connectedAddress,
  //       to: WR_KEY_CONTRACT_ADDRESS,
  //       data: this.wrKeyContract.methods.claim(quantity, proof).encodeABI(),
  //     };

  //     return this.handleSendTransaction(transaction, callbacks);
  //   }

  async getFactionNFTTotalSupply(): Promise<number> {
    const totalSupply = await this.factionNFTContract.methods
      .totalSupply()
      .call();

    return totalSupply;
  }

  async getFactionsByOwner(owner: string): Promise<FactionMetaData[]> {
    const {ownedNfts = []} = await this.alchemy.nft.getNftsForOwner(owner, {
      contractAddresses: [FACTION_NFT_CONTRACT_ADDRESS],
    });

    const tokenIds: number[] = ownedNfts.map((nft) => {
      const {tokenId} = nft;

      return this.web3.utils.isHexStrict(tokenId)
        ? (this.web3.utils.hexToNumber(tokenId) as number)
        : Number(tokenId);
    });

    const res = await Promise.all(tokenIds.map((id) => getFactionMetadata(id)));

    return res;
  }

  async getFactionTokenIds(owner: string): Promise<string[]> {
    const {ownedNfts = []} = await this.alchemy.nft.getNftsForOwner(owner, {
      contractAddresses: [FACTION_NFT_CONTRACT_ADDRESS],
    });

    return ownedNfts.map((nft) => {
      const {tokenId} = nft;

      return this.web3.utils.isHexStrict(tokenId)
        ? this.web3.utils.hexToString(tokenId)
        : String(tokenId);
    });
  }

  getTransaction(transactionHash: string) {
    return this.alchemy.core.getTransaction(transactionHash);
  }

  async claimWrab(
    {
      connectedAddress,
      amount,
      proof,
    }: {
      connectedAddress: string;
      amount: number;
      proof: string[];
    },
    callbacks: TransactionCallbacks = {}
  ): Promise<TransactionReceipt> {
    const transaction = {
      from: connectedAddress,
      to: WRAB_DISTRIBUTOR_CONTRACT_ADDRESS,
      data: this.wrabDistributorContract.methods
        .claim(amount, proof)
        .encodeABI(),
    };

    return this.handleSendTransaction(transaction, callbacks);
  }

  async claimWrabV2({
    connectedWallet,
    amount,
    proof,
  }: {
    connectedWallet: ConnectedWallet;
    amount: number;
    proof: string[];
  }) {
    const transaction = {
      from: connectedWallet.address,
      to: WRAB_DISTRIBUTOR_CONTRACT_ADDRESS,
      data: this.wrabDistributorContract.methods
        .claim(amount, proof)
        .encodeABI(),
    };

    return this.handleSendTransactionV2(connectedWallet, transaction);
  }

  async claimWrabFromV2Distributor({
    connectedWallet,
    amount,
    createdAtMs,
    signature,
  }: {
    connectedWallet: ConnectedWallet;
    amount: number;
    createdAtMs: number;
    signature: string;
  }) {
    const transaction = {
      from: connectedWallet.address,
      to: WRAB_DISTRIBUTORV2_CONTRACT_ADDRESS,
      data: this.wrabDistributorV2Contract.methods
        .claim(amount, createdAtMs, signature)
        .encodeABI(),
    };

    return this.handleSendTransactionV2(connectedWallet, transaction);
  }

  async sendWrabToReceiver({
    connectedWallet,
    amount,
  }: {
    connectedWallet: ConnectedWallet;
    amount: number;
  }) {
    const receiverAddress = process.env.NEXT_PUBLIC_ENV_WRAB_SHOP_ADDRESS;
    const transaction = {
      from: connectedWallet.address,
      to: WR_TOKEN_CONTRACT_ADDRESS,
      data: this.wrTokenContract.methods
        .transfer(receiverAddress, Utils.parseUnits(String(amount), 18))
        .encodeABI(),
    };

    return this.handleSendTransactionV2(connectedWallet, transaction);
  }

  async getUserWrabClaimed(connectedAddress: string): Promise<number> {
    const userClaimedTokens = await this.wrabDistributorContract.methods
      .userClaimedTokens(connectedAddress)
      .call();

    return Number(userClaimedTokens);
  }

  async isWrabSignatureClaimed(signature: string) {
    try {
      const isClaimed = await this.wrabDistributorV2Contract.methods
        .hasClaimedSignature(signature)
        .call();
      return !!isClaimed;
    } catch (e) {
      return false;
    }
  }
}

const web3Client = new WRWeb3Client();

export default web3Client;
